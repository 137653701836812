import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
/* import { faUtensils, faCheckSquare, faMegaphone } from "@fortawesome/free-solid-svg-icons" */
import { faBook } from "@fortawesome/free-solid-svg-icons"

import SEO from "../../components/seo"

const Faq = ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="プライバシーポリシー"
      pagedesc="iftiny（イフティニー）のプライバシーポリシーです。"
      pagepath={location.pathname}
      pageimg={data.faq.childImageSharp.original.src}
      pageimgw={data.faq.childImageSharp.original.width}
      pageimgh={data.faq.childImageSharp.original.height}
    />
    <div className="eyecatch">
      <figure>
        <Img
          fluid={data.faq.childImageSharp.fluid}
          alt="iftiny（イフティニー）のプライバシーポリシーです。"
          loading="eager"
          durationFadeIn={100}
        />
      </figure>
    </div>
    <article className="content">
      <div className="container">
        <aside className="info">
          <div className="subtitle">
            <h1 className="bar"><FontAwesomeIcon icon={faBook} />プライバシーポリシー</h1>
          </div>
        </aside>
          <div className="postbody">
            <p>
              私たちiftinyは、テクノロジーを通じて子どもとその周りの大人の生活をより便利で豊かにするとともに、さまざまな社会課題を解決していきたいと考えています。<br /><br />
              私たちは社会の一員として、何を必要とされているのか、社会で何が起きているのか、なぜそれが起きているのか、どういう課題があるのかを理解する必要があると考えます。
              そして私たちは、課題を理解し解決するために、お客様のプライバシーに関連する情報を含むさまざまな情報を活用します。
              個人情報がすべての人にとって、重要な情報であると深く認識し、すべての個人情報に関して、個人の人格尊重の理念の下、慎重に取り扱わなくてはならないと考えます。<br /><br />
              この考え方のもと、子どもに愛され、自らも誇れる企業となるために、当社が提供するサービス（以下,「サービス」といいます。）におけるプライバシーポリシーを定め、個人情報保護に努めます。<br />
            </p>
            <br />

            <div className="inner">
              <h2>個人情報保護基本方針</h2>
              <p>
                ・個人情報は、適法かつ適正な方法で取得します。<br />
                ・個人情報の利用の目的をできる限り特定し、当該目的の達成に必要な範囲内で適切に取扱います。<br />
                ・個人情報は、本人の同意なく第三者に提供しません。<br />
                ・個人情報の管理にあたっては、漏洩・滅失・毀損の防止及び是正に、適切な措置を講じるよう努めます。<br />
                ・個人情報の取扱いにあたっては、その情報を提供した本人が適切に関与し得るよう努めます。
              </p>
              <br />

              <h2>個人情報</h2>
              <p>
                個人情報とは、生存する個人に関する情報であって、氏名、生年月日、住所、電話番号、メールアドレス、
                その他の記述等により特定の個人を識別できる情報、及び他の情報と照合することによって特定の個人を識別することができることとなるものを指します。
              </p>
              <br />

              <h2>個人情報の収集・利用目的</h2>
              <p>
                個人情報の利用目的は以下の通りです。利用目的を超えて利用することはありません。<br /><br />
                ・ユーザーの個人認証、本人確認<br />
                ・ユーザー向けサービスの提供<br />
                ・商品の送付、利用料金の支払いや請求<br />
                ・利用特典の発行・計算等の運営<br />
                ・キャンペーン・モニター・取材・アンケート等の実施<br />
                ・サービスの利用に伴う連絡<br />
                ・メールマガジン・DM・各種お知らせ等の配信や送付<br />
                ・端末情報・属性情報・位置情報・行動履歴等に基づく広告やコンテンツ等の配信と表示、サービスの提供<br />
                ・サービスの改善、新規サービスの開発、および各種マーケティング調査<br />
                ・ユーザーの承諾・申込みに基づく、サービス利用企業等への個人情報の提供<br />
                ・サービスに関するご意見、お問合せ、クチコミ投稿内容の確認や回答<br />
                ・利用規約遵守状況の調査と、それに基づく当該申込内容の詳細確認<br />
                ・上記の利用目的に付随する目的
              </p>
              <br />

              <h2>収集・利用目的の変更</h2>
              <p>
                ・利用目的が変更前と関連性を有する場合や新しいサービス提供の場合、個人情報の利用目的を変更することがあります。<br />
                ・利用目的の変更を行った場合は、変更後の目的について、当社所定の方法によりユーザーに通知し、または本ウェブサイト上に公表します。<br />
              </p>
              <br />

              <h2>個人情報の第三者への提供</h2>
              <p>
                ユーザーよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。<br />
                <br />
                ・ユーザーが第三者に不利益を及ぼすと判断した場合<br />
                ・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の承諾を得ることが困難である場合<br />
                ・国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合<br />
                ・裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合<br />
                ・ユーザー本人から明示的に第三者への開示または提供を求められた場合<br />
                ・法令により開示または提供が許容されている場合<br />
                ・合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合<br />
                ・本人確認、請求先確認及び与信調査のため、決済会社に開示または提供する場合<br />
                ・配送やお知らせ送付などサービス提供に必要な場合<br />

                <br />
                前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。<br />
                <br />
                ・当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合<br />
                ・合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
                ・個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、
                  共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、
                  あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
              </p>
              <br />

              <h2>第三者提供に関する免責事項</h2>
              <p>
                以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。<br />
                <br />
                ・ユーザー本人が何らかの手段を用いて利用企業等の第三者に個人情報を明らかにする場合<br />
                ・本サービスに入力した情報により、期せずして本人が特定できてしまった場合<br />
                ・本サービスからリンクされる外部サイトにおいて、ユーザーより個人情報が提供され、またそれが利用された場合<br />
                ・ユーザー本人以外がユーザー個人を識別できる情報を入手した場合<br />
              </p>
              <br />


              <h2>個人情報の変更等</h2>
              <p>
                ユーザーは、当社のお預かりする自己の個人情報が誤った情報である場合には、
                当社に対して個人情報の訂正、追加または削除を請求することができます。下記お問合せ先よりお知らせ下さい。<br />
                当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、
                当該個人情報の訂正等を行います。また、訂正等を行った場合、もしくは訂正等を行わない旨の決定をした場合は、これをユーザーに通知します。
              </p>
              <br />

              <h2>統計データの利用</h2>
              <p>
                当社は、提供を受けた個人情報をもとに作成した個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。
              </p>
              <br />

              <h2>端末情報・属性情報・位置情報・行動履歴等の取得と利用</h2>
              <p>
                  当社は、ユーザーのプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookieを使用します。
                  Cookieとはお客様が当社ウェブサイトをご覧になったという情報を、そのお客様の機器内に記憶させておく機能のことです。
                  Cookieを利用することによりご利用の機器のウェブサイト訪問回数や訪問したページなどの情報を取得することができます。
                  なお、Cookieを通じて収集する情報に個人情報は一切含まれておりません。<br />
                  また、サービスでは、ユーザーに適切な広告を配信するために、行動ターゲティング広告サービスを利用しています。
                  そのほか、当社はユーザーがログインしてサービスを利用した場合には、個人を特定したうえで、
                  当該ログイン以前からの行動履歴等を用いて、広告・コンテンツ等の配信・表示およびサービスの提供を行う場合があります。<br />
                  各種機能は、それぞれツールが使われており、各ツールは提供企業のプライバシーポリシーに基づいて管理されます。
                  各ツール提供企業のオプトアウトページからお客様自身で無効化することにより、利用を停止することができます。<br />

                  <br />
                  <a href="https://support.google.com/analytics/answer/181881?hl=ja&ref_topic=2919631"target="_blank" rel="noopener noreferrer">
                    <span>・Google Analytics</span>
                  </a>
                  <br />

                  <a href="https://support.google.com/ads/answer/2662922?hl=ja"target="_blank" rel="noopener noreferrer">
                    <span>・グーグル合同会社</span>
                  </a>
                  <br />

                  <a href="https://www.amazon.co.jp/adprefs/ref=ya_oo_1?is-secure=true&<"target="_blank" rel="noopener noreferrer">
                    <span>・アマゾンジャパン合同会社</span>
                  </a>
                  <br />

                  <a href="https://www.facebook.com/about/ads/"target="_blank" rel="noopener noreferrer">
                    <span>・フェイスブックジャパン株式会社</span>
                  </a>
                  <br />

                  <a href="http://btoptout.yahoo.co.jp/optout/preferences.html"target="_blank" rel="noopener noreferrer">
                    <span>・ヤフー株式会社</span>
                  </a>
                  <br />

                  <a href="https://business.twitter.com/ja/help/troubleshooting/how-twitter-ads-work.html"target="_blank" rel="noopener noreferrer">
                    <span>・Twitter, Inc.</span>
                  </a>
              </p>
              <br />

              <h2>プライバシーポリシーの変更</h2>
              <p>
                当社は、法令等の定めがある場合を除き、プライバシーポリシーを随時変更することができるものとします。
              </p>
              <br />

              <h2>お問い合わせ窓口</h2>
              <p>
                <a href="../contact/"target="_blank" rel="noopener noreferrer">
                  <span>当社お問い合わせフォーム</span>
                </a>
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />


            </div>
          </div>
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    faq: file(relativePath: { eq: "faq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`
export default Faq
